/* eslint-disable no-console */
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/nextjs'
import type { AxiosError } from 'axios'
import axios, { isAxiosError } from 'axios'
import { initializeApp } from 'firebase/app'
import type { Auth, ConfirmationResult } from 'firebase/auth'
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  RecaptchaVerifier,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from 'firebase/auth'
import { useSetAtom } from 'jotai'
import { useRouter } from 'next/router'
import { toast } from 'sonner'

import { validateContacts as checkValidateContactApi } from '@/api/__generated__/public/auth/auth'
import {
  addSmsLoginMethod as addSmsLoginMethodApi,
  authDeleteUser as deleteUserApi,
  getMe as getMeApi,
  lineAddLoginMethod as lineAddLoginMethodApi,
  lineLogin as lineLoginApi,
  lineRegistration as startTalentRegistrationViaLineAuthentication,
  login as loginApi,
  logout as logoutApi,
  registration as registrationApi,
  sessionInitialize as sessionInitializeApi,
} from '@/api/__generated__/talent/auth/auth'
import type { DeleteRequest,RegistrationRequest } from '@/api/__generated__/talent/zCareerAPIForTalent.schemas'
import { firebaseConfig, firebaseEmulating, sessionCookieName } from '@/config'
import { SITE_URL } from '@/const/url'
import { authMeAtom } from '@/globalState/auth'
import { useRegistrationParams } from '@/hooks/registration/useRegistrationParams'
import { isProd } from '@/modules/utils/env'
import { sendClarityFiredEvent, sendClarityUserId } from '@/modules/utils/gtm/clarityEventActions'
import { sendGASignUp, setGAConfig } from '@/modules/utils/gtm/gaEventActions'
import type { UseAuthReturn } from '@/types/hooks/useAuth'
import {
  deleteAgentLPCookies,
  InflowCookieKeys,
} from '@/utils/clientCookieHandler/clientCookieHandler'
import { checkNotSendSMS } from '@/utils/validation/checkNumber'

import { useAuthGlobal } from './useAuthGlobal'
import type { KeptJobsCookie } from './useKeepJob'
import { useKeepJob } from './useKeepJob'
import { useRedirectAfterLogin } from './useRedirectAfterLogin'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recaptchaVerifier: RecaptchaVerifier
    confirmationResult: ConfirmationResult
    recaptchaVerifierCallback: (response: any) => void
  }
}

const app = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(app)
firebaseAuth.languageCode = 'ja'

if (firebaseEmulating) {
  connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
}

export const useAuth = (): UseAuthReturn => {
  const setMe = useSetAtom(authMeAtom)
  const { setAuthInfo } = useAuthGlobal()
  const [cookies, setCookies] = useCookies([
    'keptJobs',
    sessionCookieName,
    InflowCookieKeys.COOKIE_FROM_INFLOW,
  ])
  const { appendRegistrationParams } = useRegistrationParams()
  // Firebaseの初期化
  let recaptchaVerifierElement: HTMLElement | null = null

  const { postKeepJobs } = useKeepJob()

  const { clearRedirectUrl } = useRedirectAfterLogin()

  const [isConfirm, setIsConfirm] = useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [confirmFunc, setConfirmFunc] = useState<ConfirmationResult>()
  const [idToken, setIdToken] = useState<string>()
  const [phoneNumberRecaptcha, setpPoneNumberRecaptcha] = useState<RecaptchaVerifier | null>(null)
  const [resendVerificationRecaptcha, setResendVerificationRecaptcha] =
    useState<RecaptchaVerifier | null>(null)
  const [isDuplicatedPhoneNumber, setIsDuplicatedPhoneNumber] = useState(false)
  const [isDuplicatedLine, setIsDuplicatedLine] = useState(false)
  const [isApprovedPhoneNumber, setIsApprovedPhoneNumber] = useState(true)
  const [canResend, setCanResend] = useState(true)
  const router = useRouter()
  const isRegistration = router.pathname.includes('registration')

  /**
   * signInWithPhoneNumberのメソッド
   * ここで電話番号を使用してSMSを送信する処理を実施している
   * @param auth
   * @param phoneNumber
   * @param appVerifier
   */
  const onSignInSubmit = (auth: Auth, phoneNumber: string, appVerifier: RecaptchaVerifier) => {
    const formattedPhoneNumber = `+81${phoneNumber}` // 例: 日本の場合は"+81"を追加する
    signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        // 確認コードを入れて叩く関数をstateに保存
        setConfirmFunc(confirmationResult)
        appVerifier.clear()
        toast.success('認証コードを送信しました。')
        setIsVerifyLoading(false)
        // 確認コード入力画面へと表示を切り替えている
        setIsConfirm(true)
      })
      .catch((err: unknown) => {
        setIsVerifyLoading(false)
        console.error('error in useAuth.ts onSignInSubmit', err)
        if (isAxiosError(err) && err.code && err.code.includes('too-many-requests')) {
          toast.error('認証コード送信の上限に達しました。時間を置いて再度お試しください。')
          return
        }
        toast.error('エラーが発生しました。画面を更新して再度お試しください。')
      })
  }

  /**
   * 開発用のログイン処理
   * @param auth
   * @param phoneNumber
   * @param appVerifier
   */
  const onSignInSubmitDev = (auth: Auth, phoneNumber: string) => {
    setIsVerifyLoading(false)
    setIsConfirm(true)

    // 電話番号を国際形式に
    const formattedPhoneNumber = `+81${phoneNumber.slice(1)}`

    // テスト用にメールアドレスとパスワードで認証を行う
    const confirmationResult = {
      confirm: async (password: string) => {
        try {
          // 電話番号をメールアドレス形式に変換して使用
          const email = `${formattedPhoneNumber}@example.com`
          // 登録かログインかで処理を分岐
          if (isRegistration) {
            // 新規ユーザーを作成
            return await createUserWithEmailAndPassword(auth, email, password)
          } else {
            // 既存ユーザーでログイン
            return await signInWithEmailAndPassword(auth, email, password)
          }
        } catch (err) {
          console.error('Credential error:', err)
          toast.error('エラーが発生しました。画面を更新して再度お試しください。')
          throw err
        }
      },
      verificationId: formattedPhoneNumber, // ここも電話番号を使用
    }

    setConfirmFunc(confirmationResult)
    toast.success('開発用：パスワードを入力してください')
  }

  /**
   * 電話番号を入力した後のボタン押下時処理
   * @param phoneNumber
   * @returns
   */
  const submit = async (phoneNumber: string) => {
    setIsVerifyLoading(true)
    if (phoneNumberRecaptcha) {
      onSignInSubmit(firebaseAuth, phoneNumber, phoneNumberRecaptcha)
      return
    }
    // 契機となるボタンの要素を取得
    recaptchaVerifierElement = document.getElementById('sign-in-button')
    if (!recaptchaVerifierElement) {
      // エラーハンドリング：指定したIDの要素が存在しない場合
      console.error('no button')
      toast.error('エラーが発生しました。画面を更新して再度お試しください。')
      setIsVerifyLoading(false)
      return
    }

    // RecaptchaVerifier(認証する際に必要なやつ)を作成し描画する
    const recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, recaptchaVerifierElement, {
      size: 'invisible',
    })
    setpPoneNumberRecaptcha(recaptchaVerifier)
    onSignInSubmit(firebaseAuth, phoneNumber, recaptchaVerifier)
  }

  /**
   * 電話番号を入力した後のボタン押下時処理
   * @param phoneNumber
   * @returns
   */
  const submitDev = async (phoneNumber: string) => {
    setIsVerifyLoading(true)
    if (phoneNumberRecaptcha) {
      onSignInSubmitDev(firebaseAuth, phoneNumber)
      return
    }
    onSignInSubmitDev(firebaseAuth, phoneNumber)
  }

  /**
   * 認証コード再送信用処理
   * 古い認証コード再送信用処理。呼び出し元で再送信の時間計測や再送信可能かの状態を確認してから再送信処理を行う
   */
  const resendVerificationCode = async (phoneNumber: string) => {
    // 2回目の再送信以降にstateに保存されていたらrecaptchaが描画されているのでコード再送信のみする
    if (resendVerificationRecaptcha) {
      onSignInSubmit(firebaseAuth, phoneNumber, resendVerificationRecaptcha)
      return
    }

    // 以下はsubmitと同じ処理
    recaptchaVerifierElement = document.getElementById('resend-button')
    if (!recaptchaVerifierElement) {
      // エラーハンドリング：指定したIDの要素が存在しない場合
      console.error('no button')
      toast.error('エラーが発生しました。画面を更新して再度お試しください。')
      return
    }

    const recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, recaptchaVerifierElement, {
      size: 'invisible',
    })
    setResendVerificationRecaptcha(recaptchaVerifier)
    onSignInSubmit(firebaseAuth, phoneNumber, recaptchaVerifier)
  }

  /**
   * 認証コード再送信用処理
   * 60秒計測など呼び出し元の処理がほぼ共通していたので関数内で処理する新しい関数を作成(今後はこれを使えばいいかも)
   * canResendの状態を確認してから再送信処理を行い、60秒後に再度送信可能にする
   */
  const newResendVerificationCode = async (phoneNumber: string) => {
    if (canResend) {
      setCanResend(false)

      // 2回目の再送信以降にstateに保存されていたらrecaptchaが描画されているのでコード再送信のみする
      if (resendVerificationRecaptcha) {
        onSignInSubmit(firebaseAuth, phoneNumber, resendVerificationRecaptcha)
        return
      }

      // 以下はsubmitと同じ処理
      recaptchaVerifierElement = document.getElementById('resend-button')
      if (!recaptchaVerifierElement) {
        // エラーハンドリング：指定したIDの要素が存在しない場合
        console.error('no button')
        toast.error('エラーが発生しました。画面を更新して再度お試しください。')
        return
      }

      const recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, recaptchaVerifierElement, {
        size: 'invisible',
      })
      setResendVerificationRecaptcha(recaptchaVerifier)
      onSignInSubmit(firebaseAuth, phoneNumber, recaptchaVerifier)

      setTimeout(() => {
        setCanResend(true)
      }, 60000)
    }
  }

  /**
   * 未認証時にキープしている求人があった場合に全てお気に入りに登録する
   */
  const keepJobRegistration = () => {
    const cookie = cookies.keptJobs as KeptJobsCookie[]
    if (cookie && cookie.length !== 0) {
      const jobIds = cookie.map((v) => {
        return { job_id: v.jobId, kept_at: v.keepTime }
      })
      postKeepJobs(jobIds).then((res) => {
        if (res) {
          setCookies('keptJobs', [], {
            maxAge: 90 * 24 * 60 * 60,
            path: '/',
          })
        }
      })
    }
  }

  const fetchMe = async () => {
    const res = await getMeApi()
      .then((data) => {
        setMe(data)
        setAuthInfo(data)
        return { status: 200, data: data }
      })
      .catch((err: AxiosError) => {
        setMe(null)
        if (err.response?.status === 401) {
          firebaseAuth.signOut()
        }
        return { status: err.response?.status ?? 500, data: null }
      })
    return res
  }

  const login = async (token: string) => {
    const res = await loginApi({ headers: { Authorization: `Bearer ${token}` } })
      .then(async (data) => {
        keepJobRegistration()
        // GA4イベント送信
        setGAConfig({ talent_id: data })
        // Sentryにユーザー情報を送信
        Sentry.setUser({
          id: data,
        })
        datadogRum.setUser({
          id: data.toString(),
        })
        sendClarityUserId()
        await fetchMe()
        return 200
      })
      .catch((err: unknown) => {
        return isAxiosError(err) ? (err.response?.status ?? 500) : 500
      })
    return res
  }

  const sessionInitialize = () => {
    sessionInitializeApi()
  }

  const lineLogin = () => {
    lineLoginApi()
      .then((data) => {
        window.location.href = data.line_login_url
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          toast.error(
            'このLINEアカウントでは既にサービスをご利用中です。退会処理後に改めてお試しください。',
          )
          return
        }
        toast.error('ログインに失敗しました。')
      })
  }

  const lineAddLoginMethod = () => {
    setIsVerifyLoading(true)
    lineAddLoginMethodApi()
      .then((data) => {
        window.location.href = data.line_login_url
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          toast.error(
            'このLINEアカウントでは既にサービスをご利用中です。退会処理後に改めてお試しください。',
          )
          return
        }
        toast.error('ログインに失敗しました。')
      })
      .finally(() => {
        setIsVerifyLoading(false)
      })
  }

  const signInWithCustomToken = async (customToken: string) => {
    return firebaseSignInWithCustomToken(firebaseAuth, customToken).then((userCredential) =>
      userCredential.user.getIdToken(),
    )
  }

  const addSmsLoginMethod = (
    idToken: string,
  ): Promise<{
    isSuccess: boolean
  }> => {
    return addSmsLoginMethodApi({ headers: { Authorization: `Bearer ${idToken}` } })
      .then(() => {
        return { isSuccess: true }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error('認証コードが有効期限切れか正しくありません')
          return { isSuccess: false }
        }
        if (err.response?.status === 409) {
          toast.error('SMS認証が登録済みのアカウントです')
          return { isSuccess: false }
        }
        console.error(err)
        toast.error('SMS認証追加に失敗しました。')
        return { isSuccess: false }
      })
  }

  const confirmError = (res: any) => {
    const errorMessage = res.message as string
    if (errorMessage.includes('too-many-attempts')) {
      toast.error('認証コード送信の上限に達しました。時間を置いて再度お試しください。')
      return
    }
    if (errorMessage.includes('invalid-verification-code')) {
      toast.error('認証コードに誤りがあります。確認の上再度送信してください。')
      return
    }
    toast.error('エラーが発生しました。画面を更新して再度実施してください。')
    console.error(res.message)
  }

  const logout = async () => {
    setIsButtonLoading(true)
    const res = await logoutApi()
      .then(() => {
        firebaseAuth.signOut()
        // GA4イベント送信
        setGAConfig({ talent_id: null })
        // Sentryにユーザー情報を削除
        Sentry.setUser(null)
        datadogRum.clearUser()
        clearRedirectUrl()
        toast.success('ログアウトされました。')
        setIsButtonLoading(false)
        return true
      })
      .catch(() => {
        toast.error('画面を更新して再度やり直してください。')
        setIsButtonLoading(false)
        return false
      })
    return res
  }

  const deleteUser = async (deleteRequest: DeleteRequest) => {
    setIsButtonLoading(true)
    const res = await deleteUserApi(deleteRequest)
      .then(() => {
        firebaseAuth.signOut()
        // GA4イベント送信
        setGAConfig({ talent_id: null })
        // Sentryにユーザー情報を削除
        Sentry.setUser(null)
        datadogRum.clearUser()
        // Cookieを削除
        deleteAgentLPCookies()
        clearRedirectUrl()
        toast.success('退会処理が完了しました。')
        setIsButtonLoading(false)
        return true
      })
      .catch(() => {
        toast.error('画面を更新して再度やり直してください。')
        setIsButtonLoading(false)
        return false
      })
    return res
  }

  /**
   * メールアドレスと電話番号を使用してすでに登録がないかチェックする
   * @returns boolean
   */
  const checkContact = async (email: string, phoneNumber: string) => {
    try {
      await checkValidateContactApi({ email, phone_number: phoneNumber })
      return false
    } catch (err: unknown) {
      return isAxiosError(err) ? err.response?.status === 409 || err.response?.status === 422 : true
    }
  }

  /**
   * Validationを行ったのち電話番号でサインアップ時の認証を行う。（認証コード送信前に発火される）
   */
  const submitAfterCheckMobile = async (phoneNumber: string, email: string) => {
    setIsDuplicatedPhoneNumber(false)
    setIsVerifyLoading(true)
    setIsApprovedPhoneNumber(true)

    // 入力された電話番号の形式チェック
    if (checkNotSendSMS(phoneNumber, isProd)) {
      const isNotApproved = false
      setIsApprovedPhoneNumber(isNotApproved)
      setIsVerifyLoading(false)
      return false
    }

    // すでに登録されているかどうかを確認する
    const isCheck = await checkContact(email, phoneNumber)
    if (isCheck) {
      setIsDuplicatedPhoneNumber(true)
      setIsVerifyLoading(false)
      return false
    }
    setIsVerifyLoading(false)
    return true
  }

  /**
   * LINE新規登録セッションスタート用エンドポイントへのPOSTリクエストを行う
   * utmパラメータがあれば追加し、未登録かどうか、重複しているかどうかを確認する
   * @param talent RegistrationRequest
   */
  const lineSignUp = async (talent?: RegistrationRequest) => {
    if (!talent) return

    try {
      const enrichedValues = appendRegistrationParams(talent)
      const isCheck = await checkContact(enrichedValues.email, enrichedValues.phone_number)
      if (isCheck) {
        setIsDuplicatedLine(true)
        return
      }

      const response = await startTalentRegistrationViaLineAuthentication(enrichedValues)
      window.location.replace(response.line_login_url)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 409) {
          toast.error(
            'このLINEアカウントでは既にサービスをご利用中です。退会処理後に改めてお試しください。',
          )
          return
        }
      }
      Sentry.captureException(error)
    }
  }

  const registration = async (
    values: RegistrationRequest,
    idToken: string,
    sourceProduct: string,
  ) => {
    const enrichedValues = appendRegistrationParams(values)
    try {
      const res = await registrationApi(enrichedValues, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      await fetchMe()
      keepJobRegistration()
      // GA4イベント送信
      setGAConfig({ talent_id: res.uId })
      // GA4イベント送信
      sendClarityFiredEvent('registration_step_1_finished')
      sendClarityUserId()
      sendGASignUp(sourceProduct)
      return { isSuccess: true, data: res }
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        if (err.response?.status === 409) {
          toast.error('この電話番号はすでに登録されています。ログインしてください')
          return { isSuccess: false, data: null }
        }
        if (err.response?.status === 422) {
          return {
            isSuccess: false,
            errorText: err.response.data.errors as { [key: string]: string }[],
          }
        }
      }
      console.error(err)
      toast.error('登録に失敗しました。')
      return { isSuccess: false, data: null }
    }
  }

  /**
   * SMSコード入力後のSubmit関数
   * @param verificationCode 送信されたSMSコード
   * @param jobId Cookieから取得したjobId
   * @param talentInformation postするtalent情報
   */
  const onSmsSubmit = async (
    verificationCode: string,
    jobId: string,
    talentInformation: RegistrationRequest,
  ) => {
    setIsVerifyLoading(true)
    confirmFunc
      ?.confirm(verificationCode)
      .then(async (res) => {
        const idToken = await res.user.getIdToken()
        const sourceProduct = jobId ? 'search' : 'scout'
        await registration(talentInformation, idToken, sourceProduct).then((v) => {
          if (v.isSuccess && v?.data) {
            if (v?.data.redirectPath === '/') {
              window.location.href = SITE_URL.ROOT
            } else {
              router.push(v.data.redirectPath || SITE_URL.SCOUT_REGISTRATION)
            }
          }
          setIsVerifyLoading(false)
        })
      })
      .catch((res) => {
        confirmError(res)
        setIsVerifyLoading(false)
      })
      .finally(() => {
        setIsVerifyLoading(false)
      })
  }

  return {
    fetchMe,
    firebaseAuth,
    isVerifyLoading,
    setIsVerifyLoading,
    isConfirm,
    setIsConfirm,
    confirmFunc,
    submit,
    idToken,
    setIdToken,
    resendVerificationCode,
    newResendVerificationCode,
    login,
    sessionInitialize,
    lineLogin,
    lineAddLoginMethod,
    registration,
    addSmsLoginMethod,
    confirmError,
    logout,
    deleteUser,
    isButtonLoading,
    setIsButtonLoading,
    checkContact,
    signInWithCustomToken,
    submitDev,
    onSignInSubmitDev,
    isDuplicatedPhoneNumber,
    submitAfterCheckMobile,
    isDuplicatedLine,
    lineSignUp,
    isApprovedPhoneNumber,
    canResend,
    onSmsSubmit,
  }
}
