import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber'

export const checkConfirmNumber = (confirmNumber: string) => {
  if (confirmNumber.length === 0) {
    return '認証コードを入力してください。'
  }
  if (!confirmNumber.match(/^\d{6}$/)) {
    return '認証コードに誤りがあります。'
  }
  return null
}

/**
 * SMSを受信できるかどうかをチェックする
 * 本番環境のみチェックする
 * @param phoneNumber
 * @param isProd 本番環境かどうか
 * @returns boolean trueがエラー
 */
export const checkNotSendSMS = (phoneNumber: string, isProd = true) => {
  if (!isProd) {
    return false
  }
  const phoneUtil = PhoneNumberUtil.getInstance()
  const number = phoneUtil.parse(phoneNumber, 'JP')
  const isPossible = phoneUtil.isPossibleNumber(number)
  const isValidNumber = phoneUtil.isValidNumber(number)
  const canReceiveSMS =
    phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE ||
    phoneUtil.getNumberType(number) === PhoneNumberType.FIXED_LINE_OR_MOBILE

  return !isPossible || !isValidNumber || !canReceiveSMS
}
