import { useSearchParams } from 'next/navigation'
import { destroyCookie, parseCookies } from 'nookies'

import { SITE_URL } from '@/const/url'

/**
 * ログイン後に元いたページに戻る機能のhooks
 * @returns
 */
export const useRedirectAfterLogin = () => {
  const searchParams = useSearchParams()

  const getToUrl = () => {
    // クエリパラメータを優先的に確認
    const queryRedirect = searchParams?.get('redirect')
    if (queryRedirect) {
      return decodeURIComponent(queryRedirect)
    }

    // クエリがない場合はCookieを確認
    const cookies = parseCookies()
    const cookieRedirect = cookies.redirect_after_login
    return cookieRedirect ? decodeURIComponent(cookieRedirect) : SITE_URL.ROOT
  }

  const clearRedirectUrl = () => {
    destroyCookie(null, 'redirect_after_login', { path: '/' })
  }

  return {
    getToUrl,
    clearRedirectUrl,
  }
}
