import { useCookies } from 'react-cookie'

import type { RegistrationRequest } from '@/api/__generated__/talent/zCareerAPIForTalent.schemas'
import { getUtmCookieJson, InflowCookieKeys } from '@/utils/clientCookieHandler/clientCookieHandler'

export const useRegistrationParams = () => {
  const [cookies] = useCookies([InflowCookieKeys.COOKIE_FROM_INFLOW])
  const fromInflow = cookies[InflowCookieKeys.COOKIE_FROM_INFLOW] as string | undefined

  const appendRegistrationParams = (values: RegistrationRequest) => {
    const utmCookieJson = getUtmCookieJson()
    // FYI: URLクエリパラメータとGTM側でdataLayerに追加された値は同じものがある場合、GTM側の値を優先する
    return {
      ...values,
      ...(fromInflow && { from_service: fromInflow }), // アプリケーションでCookieにセットした流入元
      ...(utmCookieJson && { [InflowCookieKeys.AD_PARAMS]: utmCookieJson }),
    } as RegistrationRequest
  }

  return { appendRegistrationParams }
}
