export const SITE_URL = {
  ROOT: '/',
  TERMS: '/docs/terms',
  SCOUT: '/scout',
  SCOUT_REGISTRATION: '/registration/scout',
  SCOUT_ONBOARDING_START: '/scout/onboarding/start',
  LOGIN: '/login',
  AGENT_INTERVIEW_COMPLETED: '/agent/interview/completed',
  AGENT_INTERVIEW_RESERVE: '/agent/interview/reserve',
}
