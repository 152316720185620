/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * z-career Public API
 * z-career Public API.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import type { ValidateContactsParams, ValidationError } from '../zCareerPublicAPI.schemas'
import { axiosInstance } from '../../../axios'
import type { ErrorType } from '../../../axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * 指定されたメールアドレスと電話番号が使用可能かどうかを確認する
 * @summary 連絡先が使用可能がチェックするAPI
 */
export const validateContacts = (
  params: ValidateContactsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    { url: `/public/auth/validate_contacts`, method: 'GET', params, signal },
    options,
  )
}

export const getValidateContactsQueryKey = (params: ValidateContactsParams) => {
  return [`/public/auth/validate_contacts`, ...(params ? [params] : [])] as const
}

export const getValidateContactsQueryOptions = <
  TData = Awaited<ReturnType<typeof validateContacts>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: ValidateContactsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof validateContacts>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getValidateContactsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof validateContacts>>> = ({ signal }) =>
    validateContacts(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof validateContacts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ValidateContactsQueryResult = NonNullable<Awaited<ReturnType<typeof validateContacts>>>
export type ValidateContactsQueryError = ErrorType<void | ValidationError>

/**
 * @summary 連絡先が使用可能がチェックするAPI
 */

export function useValidateContacts<
  TData = Awaited<ReturnType<typeof validateContacts>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: ValidateContactsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof validateContacts>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getValidateContactsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getValidateContactsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof validateContacts>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: ValidateContactsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof validateContacts>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getValidateContactsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof validateContacts>>> = ({ signal }) =>
    validateContacts(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof validateContacts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ValidateContactsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof validateContacts>>
>
export type ValidateContactsSuspenseQueryError = ErrorType<void | ValidationError>

/**
 * @summary 連絡先が使用可能がチェックするAPI
 */

export function useValidateContactsSuspense<
  TData = Awaited<ReturnType<typeof validateContacts>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: ValidateContactsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof validateContacts>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getValidateContactsSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}
